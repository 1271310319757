import { config } from '@/common'

function Main(props: any) {
    const year_start = 2024
    const year = (new Date()).getFullYear()
    return (
        <div className="copyright">
            技术支持
            <a href="https://mos01.com/" target='_blank'>魔思科技</a>
            © {(year === year_start) || `${year_start} - `}{year} {props.text}
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target='_blank' rel="noreferrer">{config.app.beian}</a>
        </div>
    )
}
export default Main