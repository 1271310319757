import { useState, get, useEffect, forwardRef, useImperativeHandle, useLocation, Func, useRef } from '@/common'
import { Table, Space, Button, Modal, Pagination } from 'antd';
import '../assets/style/list.scss';
import { post } from '../fetch';

function Main(props: any, ref: any) {
  const location = useLocation()
  const pathname = props.apiUrl || location.pathname
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected_row_keys, setSelectedRowKeys] = useState<any>([]);
  const [request, setRequest] = useState(props.request || {});
  const ope_authority: any = props.opeAuthority || []
  const default_get = useRef(props.defaultGet === undefined ? true : props.defaultGet)

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [page_msg, setPageMsg] = useState<Array<React.ReactNode>>([]);

  // const [pagination, setPagination] = useState<any>({
  //   showSizeChanger: true,
  //   showQuickJumper: true,
  //   pageSize: 50,
  //   // hideOnSinglePage: true,
  //   showTotal: (total: string) => {
  //     return `共 ${total} 条结果`
  //   },
  //   pageSizeOptions: [20, 50, 100, 200, 500],
  //   onChange: (page: string, page_size: string) => {
  //     setPagination({...pagination,
  //       current: page,
  //       pageSize: page_size
  //     })
  //     setRequest({
  //       ...request,
  //       page,
  //       page_size
  //     })
  //   }
  // });

  const del = () => {
    Modal.confirm({
      title: `确认是否删除选中？`,
      onOk() {
        post(`${pathname}/del`, selected_row_keys).then(data => {
          if (data.code) return
          getList();
          if (props.delSuccces && typeof (props.delSuccces) == "function") props.delSuccces()
        })
      }
    })
  }

  const getList = async () => {
    setLoading(true)
    setSelectedRowKeys([])
    return get(pathname, request).then(data => {
      setLoading(false)
      setList(data.data)
      if (data.page) {
        setTotal(data.page.total)
      }
      if (data.sum) {
        let dom: any = ['【总计】']
        data.sum.forEach((e: any, k: number) => {
          dom.push(<span key={k}>{e['name']}：<span className='num'>{e['value']}</span> {e['unit']}；</span>)
        });
        setPageMsg(dom)
      }
      if (props.success) props.success(data.data)
      return data
    })
  }

  // 暴露方法
  useImperativeHandle(ref, () => ({
    getList,
    request: (data: any) => {
      if (data) {
        let request_new = { ...request }
        if (!Object.keys(data).length) request_new = {}
        Object.keys(data).forEach(k => {
          request_new[k] = data[k]
          if (Func.isEmpty(data[k])) delete request_new[k]
        })
        setRequest(request_new)
      }
    },
    getSelectedRowKeys: () => {
      return selected_row_keys
    }
  }));

  useEffect(() => {
    if (default_get.current) {
      getList()
    }
    default_get.current = true
  }, [request])

  return (
    <>
      <Table
        className="table"
        size="small"
        columns={props.columns}
        dataSource={list}
        rowKey={props.rowKey || 'id'}
        loading={loading}
        rowSelection={(props.rowSelection === false) ? undefined : { selectedRowKeys: selected_row_keys, onChange: (k) => { setSelectedRowKeys(k) } }}
        scroll={{ y: 40 }}
        pagination={false}
        onChange={(pagination, filters, sorter: any) => {
          // 排序
          let request_1 = { ...request }
          if (sorter.order) {
            request_1['order_by'] = [sorter.field, sorter.order]
          } else if (request_1['order_by']) {
            delete request_1['order_by']
          }
          console.log(request_1);

          setRequest(request_1)
        }}
      // pagination={(props.pagination === false) ? undefined : pagination}
      />
      <div className='page'>
        <div className='msg'>{page_msg}</div>
        <div className='right'>
          <div className='total'>共 {total || list.length} 条结果</div>
          {/* <Pagination showQuickJumper hideOnSinglePage showTotal={(total) => {
          return `共 ${total} 条结果`
        }} */}
          <Pagination
            showQuickJumper
            hideOnSinglePage
            defaultPageSize={props.defaultPageSize || 50}
            current={page}
            total={total}
            pageSizeOptions={[20, 50, 100, 200, 500]}
            onChange={(page: number, page_size: number) => {
              setPage(page)
              setRequest({
                ...request,
                page,
                page_size
              })
            }}
          />
        </div>
      </div>
      <div className={`choice ${selected_row_keys.length ? 'show' : 'hide'}`}>
        <Space>
          {props.selectNode || ''}
          {
            ope_authority.indexOf("del") > -1 && <Button type="primary" danger onClick={del}>删除选中</Button>
          }
          <span>当前选中：{selected_row_keys.length}条</span>
        </Space>
      </div>
    </>
  )
}

export default forwardRef(Main)