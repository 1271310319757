import { useState, useEffect, forwardRef, useImperativeHandle } from '@/common'
import { Button, Table, Form, Modal, Space, message } from 'antd';
import {
    PlusCircleOutlined
} from '@ant-design/icons';

function Main(props: any, ref: any) {
    const [form_data] = Form.useForm();
    const [visible, setVisible] = useState<any>();
    const [datas, setDatas] = useState<any>([]);
    const [data_index, setDataIndex] = useState<number>(-1)
    const [action, setAction] = useState<string>('add');
    const [default_status, setDefaultStatus] = useState(true)
    const name = props.name || '参数'
    const successClose = props.successClose === undefined ? true : props.successClose
    const pagination = props.pagination === undefined ? false : props.pagination
    const actionText = {
        'add': '添加',
        'edit': '修改'
    }
    const labelCol = props.labelCol || { span: 5 }
    const width = props.width || 520

    // 暴露方法
    useImperativeHandle(ref, () => ({
        setFieldsValue: async (data: any) => {
            form_data.setFieldsValue(data)
        },
    }));

    const onChange = (datas: any) => {
        setDefaultStatus(false)
        setDatas([...datas])
        if (datas.length) {
            props.onChange(datas)
        } else {
            props.onChange(null)
        }
        if (props.onChanged && typeof (props.onChanged) == "function") props.onChanged(datas)
    }


    useEffect(() => {
        if (props.value && default_status) setDatas(props.value)
    }, [props])

    return (
        <div className='list'>
            <Space className='ope'>
                <Button type="primary" icon={<PlusCircleOutlined />} size="small" onClick={() => {
                    setAction('add')
                    setVisible(true)
                    form_data.resetFields()
                    if (props.addBefore && typeof (props.addBefore) == "function") props.addBefore(form_data)
                }}>添加{name}</Button>
                <div className='note'>{props.note}</div>
            </Space>
            <Table
                columns={[...props.columns, {
                    title: '操作',
                    align: 'center',
                    render: (text: string, record: any, index: number) => (
                        <Space>
                            <Button type="link" size="small" onClick={() => {
                                setAction('edit')
                                setDataIndex(index)
                                setVisible(true)
                                form_data.resetFields()
                                if (props.editBefore && typeof (props.editBefore) == "function") {
                                    form_data.setFieldsValue(props.editBefore(record, index) || record)
                                } else {
                                    form_data.setFieldsValue(record)
                                }
                            }}>修改</Button>
                            {
                                index > 0 && <Button type="link" size="small" onClick={() => {
                                    datas.splice(index, 1)
                                    datas.splice(index - 1, 0, record)
                                    onChange(datas)
                                }}>上移</Button>
                            }
                            {
                                index < datas.length - 1 && <Button type="link" size="small" onClick={() => {
                                    datas.splice(index, 1)
                                    datas.splice(index + 1, 0, record)
                                    onChange(datas)
                                }}>下移</Button>
                            }
                            <Button type="link" danger size="small" onClick={() => {
                                datas.splice(index, 1)
                                onChange(datas)
                            }}>删除</Button>
                        </Space>
                    )
                }]}
                dataSource={datas}
                size="small"
                pagination={pagination}
                rowKey={(record, index: any) => {
                    return index
                }}
            >

            </Table>
            <Modal title={actionText[action] + name}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                width={width}
                onOk={() => {
                    form_data.validateFields().then((data) => {
                        if (props.submitBefore && typeof (props.submitBefore) == "function") props.submitBefore(data)
                        if (action === 'add') {
                            datas.push(data)
                            if (successClose) setVisible(false)
                        }
                        if (action === 'edit') {
                            datas[data_index] = data
                            setVisible(false)
                        }
                        // message.success(`${actionText[action]}成功`)
                        form_data.resetFields()
                        onChange(datas)
                    })
                }}
                maskClosable={false}
            >
                <Form form={form_data} className="form" labelCol={labelCol}>
                    {props.children}
                </Form>
            </Modal>
        </div>
    )
}
export default forwardRef(Main)