import { get, post, useState, useMemo, useRef, useEffect } from '@/common'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

async function fetchOptions(url: string, value: string) {
    // if (Func.testPhone(phone)) {
    return get(url, { filter: value }).then((data) => {
        if (!data.code) {
            return data.data.map((e: any) => {
                return {
                    label: `${e.phone}${e.name ? `（${e.name}）` : ''}`,
                    value: e.id
                }
            })
            // return [{
            //     label: `${data.phone}${data.name ? `（${data.name}）` : ''}`,
            //     value: data.id,
            // }]
        }
    })
    // }
}

function Main(props: any) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceTimeout = 800
    const [default_status, setDefaultStatus] = useState(true)

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: any) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            // setOptions([]);
            setFetching(true);
            fetchOptions(props.url, value).then((newOptions: any) => {
                if (newOptions !== undefined) {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    setOptions(newOptions);
                    setFetching(false);
                }
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    const onChange = (arr: any) => {
        let members: any = [];
        arr.forEach((e: any) => {
            members.push(e.value)
        });
        props.onChange(members);
    }

    useEffect(() => {
        // 执行一次
        if (default_status && props.value) {
            post(props.url, props.value, false).then((data) => {
                let arr: any = []
                data.data.map((e: any) => {
                    arr.push({
                        label: `${e.phone}${e.name ? `（${e.name}）` : ''}`,
                        value: e.id
                    })
                })
                setOptions(arr)
            })
            setDefaultStatus(false)
        }
    }, [props])

    return (
        <Select
            mode="multiple"
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            {...props}
            onChange={onChange}
        />
    )
}

export default Main